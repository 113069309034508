<template>
  <div
    class="custom-layout-container m-auto"
    :class="{ 'sm-custom-layout-container': !isPreview }"
    :style="customLayoutContainerStyle"
  >
    <div
      class="custom-layout-image"
      :class="{ 'sm-custom-layout-image': !isPreview && layout !== 'full' }"
      :style="customLayoutImageStyling"
    >
      <div class="image-container relative w-100 h-100 object-fit-cover">
        <img
          v-show="currentPageStyling.backgroundImage.image"
          class="w-100 h-100 object-fit-cover"
          :src="currentPageStyling.backgroundImage.image"
          alt="Background Image"
        />
        <img
          v-show="
            !currentPageStyling.backgroundImage.image && layout !== 'full'
          "
          class="w-100 h-100 object-fit-cover"
          src="@/assets/images/default-bg.png"
          alt="Default Background Image"
        />
      </div>
    </div>
    <slot name="formester-form"></slot>

    <div class="branding-navigation-wrapper">
      <div class="free-branding__wrapper mx-4">
        <FreeBranding
          v-if="isFreeUser"
          :is-multipage-form="showNavigationButtons"
          :first-color="firstColor"
          :second-color="secondColor"
        />
      </div>
      <div class="progress-navigation-arrow__wrapper">
        <ProgressIndicator
          v-if="showProgressIndicator"
          :current-page="pageIndex"
          :pages="pageIds"
          :styling="styling"
          :first-color="firstColor"
          :second-color="secondColor"
        />
        <PageNavigationArrow
          v-if="showNavigationButtons"
          :styling="styling"
          :first-color="firstColor"
          :second-color="secondColor"
          :page-index="pageIndex"
          :is-free-user="isFreeUser"
          :total-pages="totalPages"
          @prev="onPrev"
          @next="onNext"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import ProgressIndicator from "./components/progress-indicator.vue";
import PageNavigationArrow from "./components/page-navigation-arrow.vue";
import FreeBranding from "./components/free-branding.vue";

import { rgbaToSolidRgb, getVisibleColor } from "@/helpers/utils";

const emit = defineEmits(["next", "prev"]);

const props = defineProps({
  layout: {
    type: String,
    required: true,
  },
  styling: {
    type: Object,
    required: true,
  },
  isPreview: {
    type: Boolean,
    required: false,
  },
  previewWidth: {
    type: String,
    default: "750",
  },
  formMaxWidth: {
    type: String,
    required: true,
  },
  device: {
    type: String,
    default: "desktop",
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  isFreeUser: {
    type: Boolean,
    required: true,
  },
  totalPages: {
    type: Number,
    required: true,
  },
  pageIds: {
    type: Array,
    required: true,
  },
  showProgressIndicator: {
    type: Boolean,
    required: true,
  },
  showNavigationButtons: {
    type: Boolean,
    required: true,
  },
  currentPageStyling: {
    type: Object,
    default: () => ({}),
  },
});

const firstColor = computed(() => {
  let color = rgbaToSolidRgb(
    props.styling.buttonColor,
    props.styling.backgroundColor,
  );

  color = getVisibleColor(color, secondColor.value);

  return color;
});

const secondColor = computed(() => {
  return rgbaToSolidRgb(props.styling.backgroundColor);
});

const customLayoutContainerStyle = computed(() => {
  const styles = {};
  if (props.device !== "desktop" && props.isPreview) {
    styles.maxWidth = `${props.previewWidth || props.formMaxWidth || 750}px`;
    styles.minWidth = "auto";
  }

  if (
    props.layout === "right" &&
    (props.device === "desktop" || !props.isPreview)
  ) {
    styles.flexDirection = "row-reverse";
  }

  if (props.isPreview && props.device !== "desktop") {
    styles.flexDirection = "column";
    styles.alignItems = "center";
  }

  return styles;
});

const customLayoutImageStyling = computed(() => {
  if (props.layout === "full") {
    return {
      position: "absolute",
      width: "100%",
      height: "100%",
    };
  } else if (props.device !== "desktop" && props.isPreview) {
    return {
      minWidth: "100%",
      height: "25vh",
    };
  }
  return {};
});

// Methods
const onPrev = () => {
  emit("prev");
};

const onNext = () => {
  emit("next");
};
</script>

<style scoped>
.custom-layout-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
}
.custom-layout-image {
  width: 50%;
}
.image-container::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--overlay-color);
  opacity: var(--overlay-opacity);
}

.free-branding__wrapper {
  position: absolute;
  bottom: 1rem;
  left: 0;
  z-index: 1;
}

.progress-navigation-arrow__wrapper {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  position: absolute;
  bottom: 1rem;
  right: 14px;
  z-index: 1;
}

.branding-navigation-wrapper {
  position: absolute;
  z-index: 5;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}

/* Survery Form */
@media only screen and (max-width: 576px) {
  .sm-custom-layout-container {
    flex-direction: column !important;
    overflow: auto;
  }

  .sm-custom-layout-image {
    width: 100%;
    height: 25vh;
  }

  .free-branding__wrapper {
    position: fixed;
    bottom: 16px;
  }

  .progress-navigation-arrow__wrapper {
    position: fixed;
    bottom: 13px;
  }
}
</style>
