export default class NetworkStatus {
  constructor() {
    this.online = false; // Assume offline initially
    this.imgUrl = "/assets/images/1x1.png";
    this.onlineListeners = [];
    this.offlineListeners = [];
    this.statusListeners = [];
    this.checkInterval = 30000; // Check every 30 seconds

    // Start checking periodically
    this.intervalId = setInterval(() => {
      this.checkOnlineStatus();
    }, this.checkInterval);
  }

  addEventListener(event, callbackFunction) {
    switch (event) {
      case "online":
        this.onlineListeners.push(callbackFunction);
        break;
      case "offline":
        this.offlineListeners.push(callbackFunction);
        break;
      case "status":
        this.statusListeners.push(callbackFunction);
    }
  }

  async checkOnlineStatus() {
    try {
      const response = await fetch(this.imgUrl, { cache: "no-store" });
      this.online = response.status >= 200 && response.status < 300;
      this.notifyListeners(this.onlineListeners);
      this.notifyListeners(this.statusListeners);
    } catch (err) {
      this.online = false;
      this.notifyListeners(this.offlineListeners);
      this.notifyListeners(this.statusListeners);
    }
  }

  notifyListeners(listeners) {
    listeners.forEach((listener) => {
      try {
        listener(this.online);
      } catch (error) {
        console.error("Error in network status listener:", error);
      }
    });
  }

  destroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.intervalId);
  }
}

// Create a singleton instance of NetworkStatus
const networkStatus = new NetworkStatus();
export { networkStatus };
