<template>
  <div class="empty-form">
    <img
      src="@/assets/images/empty-form.svg"
      alt="Empty Form"
      height="100"
      width="100"
    />
    <p class="message mt-2">Your form is empty!</p>
  </div>
</template>

<script></script>

<style>
.empty-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
