<template>
  <div class="progress-track" :style="trackStyle" />

  <div class="progress-value">
    <div :style="progressStyle" />
  </div>
  <div class="progress-indicator">
    <svg>
      <circle cx="25" cy="25" r="22" :style="progressPlaceholderStyle" />
      <circle cx="25" cy="25" r="22" :style="progressIndicatorStyle" />
    </svg>
    <span class="percentage-number" :style="progressPercentageIndicatorStyle">
      {{ currentProgressPercentage }}
    </span>
  </div>
</template>

<script>
import { addAlpha } from "@/helpers/utils";

export default {
  name: "ProgressIndicator",
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    pages: {
      type: Array,
      required: true,
    },
    styling: {
      type: Object,
      required: true,
    },
    firstColor: {
      type: String,
      required: true,
    },
    secondColor: {
      type: String,
      required: true,
    },
  },
  computed: {
    progressBarColor() {
      return this.styling.progressColor || this.firstColor || "#000000";
    },
    currentProgressPercentage() {
      return `${Math.round((this.currentPage * 100) / this.pages.length)}%`;
    },
    progressIndicatorStyle() {
      return {
        stroke: this.progressBarColor,
        "stroke-dashoffset":
          138.23 -
          (138.23 * (Math.round(this.currentPage * 100) / this.pages.length)) /
            100,
      };
    },
    progressPlaceholderStyle() {
      return {
        // TODO: find what should be the right color for this from ishan?
        // right now it's just rendering a grey color
        stroke: addAlpha(this.progressBarColor, 0.1),
      };
    },
    progressPercentageIndicatorStyle() {
      return {
        color: this.styling.fontColor,
      };
    },
    progressStyle() {
      return {
        "background-color": this.progressBarColor,
        width: `${(this.currentPage * 100) / this.pages.length}%`,
        transition: "width 0.4s ease-out",
      };
    },
    trackStyle() {
      return {
        "background-color": this.progressBarColor,
      };
    },
  },
};
</script>

<style scoped>
.progress-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}
.progress-indicator svg {
  width: 50px;
  height: 50px;
  transform: rotate(-90deg);
}
.progress-indicator svg circle {
  fill: none;
  stroke-width: 5;
  stroke-linecap: round;
  stroke: #efefef;
  stroke-dasharray: 138.23;
  stroke-dashoffset: 0;
  transition: all 0.4s ease-out;
}
.percentage-number {
  position: absolute;
  margin: auto;
  font-size: 12px;
  font-weight: 700;
}

.progress-track {
  width: 100%;
  height: 4px;
  position: fixed;
  opacity: 0.2;
  top: 0;
  z-index: 9998;
}

.progress-value {
  height: 4px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
}
.progress-value > div {
  height: 100%;
}
@media only screen and (max-width: 576px) {
  .progress-indicator {
    display: none;
  }
}
</style>
