import { ref, computed } from "vue";

import {
  getCollaborativeForm,
  updateCollaborativeForm,
} from "@/api/collaborative_forms";

import { getDraftSubmissionData } from "@/api/draft_submissions";

const STATUS = {
  IN_PROGRESS: "in_progress",
  SUBMITTED: "submitted",
};

export function useCollaborativeFormSubmission(formId, sessionId) {
  // State
  const formSessionId = ref(sessionId);
  const query = new URLSearchParams(window.location.search);
  const uuid = query.get("collaborative_form_submission");
  const collaborativeForm = ref({});
  const tempFormData = ref(new FormData()); // To store the temporary data which needs to be synced with the draft submission (backend)

  // Computed
  const isCollaborativeFormSubmissionEnabled = computed(
    () => uuid && collaborativeForm.value.uuid === uuid,
  );

  // Methods
  async function verifyAndFetchCollaborativeSubmission() {
    let data;

    // Verify the collaborative form submission uuid
    if (uuid) {
      try {
        const { data } = await getCollaborativeForm(formId, uuid);
        collaborativeForm.value = data;
      } catch (error) {
        console.warn("Could not fetch collaborative form submission", error);
      }
    }

    // Prefill collaborative form submission if enabled and the uuid present in the collaborativeForms list
    if (isCollaborativeFormSubmissionEnabled.value) {
      data = await getFormDataFromDraftSubmission(uuid);
    }

    return data;
  }

  async function getFormDataFromDraftSubmission(submissionId) {
    formSessionId.value = submissionId;
    try {
      const { data } = await getDraftSubmissionData(formId, submissionId);
      return data;
    } catch (error) {
      console.warn("Could not fetch draft submission data:", error);
    }
  }

  async function updateCollaborativeFormStatus(statusCode) {
    if (!collaborativeForm.value.uuid) return;

    // return if the status is already present
    if (statusCode === STATUS.IN_PROGRESS && collaborativeForm.value.status)
      return;

    try {
      await updateCollaborativeForm(formId, uuid, {
        status: statusCode,
      });

      collaborativeForm.value.status = statusCode;
    } catch (error) {
      console.warn("Could not update collaborative form status:", error);
    }
  }

  function clearTempFormData() {
    tempFormData.value = new FormData();
  }

  return {
    formSessionId,
    collaborativeForm,
    verifyAndFetchCollaborativeSubmission,
    getFormDataFromDraftSubmission,
    isCollaborativeFormSubmissionEnabled,
    updateCollaborativeFormStatus,
    tempFormData,
    STATUS,
    clearTempFormData,
  };
}
