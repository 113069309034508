<template>
  <div class="pagination" :style="paginationStyle">
    <button
      v-if="isLastPage"
      id="formester-submit"
      type="submit"
      class="default-style next-btn"
      :style="btnStyle"
      :disabled="submitting"
    >
      {{ primaryText || "Submit" }}
      <i v-if="submitting" class="fa-solid fa-circle-notch fa-spin" />
    </button>
    <button
      v-if="!isFirstPage && !showNavigationButtons"
      class="default-style pagination-btns"
      :style="prevBtn"
      @click.prevent="$emit('prev', currentPage)"
    >
      {{ secondaryText || "Back" }}
    </button>
    <button
      v-if="!isLastPage"
      class="default-style next-btn"
      :style="btnStyle"
      @click.prevent="$emit('next', currentPage)"
    >
      {{ nextButtonText }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    submitItem: {
      type: Object,
      required: true,
    },
    submitting: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    styling: {
      type: Object,
      required: true,
    },
    currentPageStyling: {
      type: Object,
      default: () => ({}),
    },
    pages: {
      type: Array,
      required: true,
    },
  },
  emits: ["next", "prev"],
  computed: {
    isFirstPage() {
      return this.currentPage === 0;
    },
    isLastPage() {
      return this.currentPage === this.totalPages - 1;
    },
    showNavigationButtons() {
      return this.styling.showNavigationButtons;
    },
    pagesExcludingThankYouPage() {
      return this.pages.filter((p) => p.type !== "thank-you");
    },
    primaryText() {
      if (this.isLastPage) {
        return this.pagesExcludingThankYouPage.slice(-1).pageStyling?.button
          ?.primaryText;
      }
      return this.currentPageStyling.button?.primaryText;
    },
    secondaryText() {
      return this.currentPageStyling.button?.secondaryText;
    },
    nextButtonText() {
      if (this.isFirstPage) {
        return this.primaryText || "Start";
      } else {
        if (this.primaryText) {
          return this.primaryText;
        } else {
          return this.showNavigationButtons ? "Continue" : "Next";
        }
      }
    },
    paginationStyle() {
      const styles = {};
      const alignment = this.currentPageStyling.button?.alignment || "left";

      styles.justifyContent = this.showNavigationButtons
        ? alignment
        : "space-between";
      return styles;
    },
    btnStyle() {
      return {
        "background-color": this.styling.buttonColor || "#00A3FF",
        "border-color": this.styling.buttonColor || "#00A3FF",
        color: this.styling.buttonTextColor || "white",
        "font-family": this.styling.fontFamily || "sans-serif",
        "font-size": this.submitItem.styling.multiPageFontSize
          ? `${this.submitItem.styling.multiPageFontSize}px`
          : "16px",
        "line-height": `${
          parseInt(this.submitItem.styling.multiPageFontSize) * 1.5
        }px`,
        "font-weight": this.submitItem.styling.labelBold ? "bold" : "normal",
        "font-style": this.submitItem.styling.labelItalic ? "italic" : "normal",
      };
    },
    prevBtn() {
      return {
        "border-color": this.styling.buttonColor || "#6d5eac",
        color: this.styling.buttonColor || "white",
        "font-family": this.styling.fontFamily || "sans-serif",
        "font-size": this.submitItem.styling.multiPageFontSize
          ? `${this.submitItem.styling.multiPageFontSize}px`
          : "16px",
        "line-height": `${
          parseInt(this.submitItem.styling.multiPageFontSize) * 1.5
        }px`,
        "font-weight": this.submitItem.styling.labelBold ? "bold" : "normal",
        "font-style": this.submitItem.styling.labelItalic ? "italic" : "normal",
      };
    },
  },
};
</script>

<style>
.pagination {
  display: flex;
  padding: 16px;
  align-items: center;
}

.pagination .default-style {
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid #6d5eac;
  color: #6d5eac;
  background-color: transparent;
  padding: 8px 17px;
  outline: none;
  cursor: pointer;
  font-family: "sans-serif";
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.pagination .default-style:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination:focus,
.default-style:focus {
  border-color: var(--btn-focus-color);
  box-shadow: 0 0 0 0.2rem var(--btn-focus-color);
}

.pagination:active,
.default-style:active {
  box-shadow: none;
}

/* .pagination .default-style:hover:disabled {
  color: #6d5eac;
  background-color: #eee;
} */

.pagination .default-style:hover {
  transform: scale(1.05);
  box-shadow: 0 0 0 0.2rem var(--btn-focus-color);
}

@media only screen and (max-width: 768px) {
  .pagination {
    padding: 6px 10px;
    margin-top: 10px;
  }
}
</style>
